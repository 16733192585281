import { gql } from 'graphql-tag';

export const singletonPagesQuery = gql`
  query pageSingletonNodes {
    pageSingletonNodes {
      contentType
      name
      url
    }
  }
`;
