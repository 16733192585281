import { computed, getCurrentInstance } from '@nuxtjs/composition-api';
import { useQuery } from '@vue/apollo-composable';

import type { PageSingletonNodesQuery, PageSingletonNodesQueryVariables } from '@/graphql/generated';
import { singletonPagesQuery } from '@/graphql/queries/singletonPagesQuery';

const map = new Map<string, string>();
map.set('shows', 'ScenkonstOtPerformanceListing');
map.set('workers', 'ScenkonstStaffListing');
map.set('articles', 'NewsListing');

type SingletonPage = PageSingletonNodesQuery['pageSingletonNodes'][0]
export const useSingletonPages = () => {
  const instance = getCurrentInstance()!;
  const frontendType = computed(() => map.get(instance.props.type as string));

  const { result, loading } = useQuery<PageSingletonNodesQuery, PageSingletonNodesQueryVariables>(singletonPagesQuery);

  return {
    singletonPage: computed(() => result.value?.pageSingletonNodes.find(page => page.contentType === frontendType.value) as SingletonPage),
    singletonPages: computed(() => result.value?.pageSingletonNodes || []),
    singletonPagesLoading: loading,
  };
};
