












import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';

import { PerformanceStatusEnum } from '@/graphql/generated';

export default defineComponent({
  name: 'ShowsStatusBanner',
  props: {
    performanceStatus: {
      type: String as PropType<PerformanceStatusEnum>,
      default: 'default',
    },
  },
  setup: (props) => {
    const showAttentionBar = computed(() => {
      return props.performanceStatus !== 'default';
    });

    const attentionBarText = computed(() => {
      let text: string = '';
      if (props.performanceStatus === 'cancelled') text = 'Inställd ';
      if (props.performanceStatus === 'sold_out') text = 'Slutsåld ';

      for (let i = 0; i < 7; i++)
        text = text.concat(text);

      return text;
    });
    return ({
      // attentionBarTextNodes,
      showAttentionBar,
      attentionBarText,
    });
  },
});
