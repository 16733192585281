















































import { Component, Prop, Vue } from 'nuxt-property-decorator';

import { formatSimpleSv } from '@/composables/datefns';

import { ScenkonstOtPerformanceContent } from '@/graphql/generated';
import { IconArrowRight } from '@/icons';

@Component({
  components: {
    IconArrowRight,
  },
})
export default class CardShow extends Vue {
  @Prop({ type: Object, required: true })
  performance!: Partial<ScenkonstOtPerformanceContent>

  attentionBarTextNodes: number = 6;

  get showAttentionBar () {
    return this.performance.status !== 'default';
  }

  get attentionBarText () {
    if (this.performance.status === 'cancelled') return 'Inställd';
    if (this.performance.status === 'sold_out') return 'Slutsåld';
  }

  get getStartToEndDate () {
    if (this.performance.seasons) {
      return this.performance.seasons.map(season => season.season).join(', ');
    } else {
      const startDate = formatSimpleSv(new Date(this.performance.startDate));
      const endDate = formatSimpleSv(new Date(this.performance.endDate));

      if (startDate === endDate)
        return startDate;
      else
        return `${startDate} – ${endDate}`;
    }
  }

  get image () {
    if (this.performance.image)
      return this.performance.image;

    return null;
  }
}
