




































import { Component, Prop, Vue } from 'nuxt-property-decorator';

import { IconArrowRight } from '@/icons';

@Component({
  components: {
    IconArrowRight,
  },
})
export default class CardWorker extends Vue {
  @Prop({ type: String, required: true })
  title!: string

  @Prop({ type: Boolean, required: true })
  shouldLink!: boolean

  @Prop({ type: String, required: false })
  subtitle!: string

  @Prop({ type: String, required: true })
  to!: string

  @Prop({ type: Object, required: false })
  image!: object

  @Prop(Boolean)
  small!: boolean
}
