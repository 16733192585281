

























import { Component, Vue, Prop } from 'nuxt-property-decorator';

import { IconArrowRight } from '@/icons';

@Component({
  components: {
    IconArrowRight,
  },
})
export default class CardArticle extends Vue {
  @Prop({ type: String, required: true })
  title!: string

  @Prop({ type: String, required: false })
  subtitle!: string

  @Prop({ type: String, required: false })
  description!: string

  @Prop({ type: String, required: true })
  to!: string

  @Prop({ required: false })
  image!: string

  @Prop(Boolean)
  withButton!: boolean
}
